<template>
  <transactions></transactions>
</template>

<script>
// @ is an alias to /src
import Transactions from '@/components/Transactions';



export default {
  name: 'TransactionsNew',
  components: {
    Transactions
  },
  data(){
    return{
      isBudget:'0'
    }
  },
  methods:{

  },
  mounted() {
    document.title = "Transactions";
  }
}
</script>

<style scoped>



</style>