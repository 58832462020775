<template>
  <button class="btn stepBtn" :class="{active:increment.id===incrementActiveId}" @click="$emit('setIncrementAmount',increment)">{{increment.name}}</button>
</template>

<script>
export default {
  name: 'Increment',
  props: ['increment','incrementActiveId'],
  data(){
    return{
    }
  },
  methods:{

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .stepBtn{
    font-size:10px;
    margin:5px;
  }
  .stepBtn.active{
    color: #fff;
    background-color: #212529;
    border-color: #212529;
  }

</style>
