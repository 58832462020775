<template>
  <div class="mainContainer container" id="mainContainer">

    <div style="display:flex;">
      <div @click="showHistory" class="backBtn clickable"  v-if="tranMode"> <font-awesome-icon :icon="['fas', 'arrow-left']" /></div>
      <back-button v-else/>
      <h6 style="margin:auto;">{{this.saveBtnTxt  + ' - ' + this.account.ACCOUNT_NAME}}</h6>
    </div>
    <hr>



    <div class="alert alert-danger d-flex expireBanner" v-if="account.EXPIRE_STATUS === '4'"><font-awesome-icon  class="expired expireIcon" :icon="['fas', 'exclamation-circle']"/> User account has expired! <div class="btn btn-success extend" @click="this.$root.gotoRoute({ path: '/extenduser' })">Extend</div></div>
    <div class="alert alert-warning d-flex expireBanner" v-if="account.EXPIRE_STATUS === '1'"><font-awesome-icon class="expiring expireIcon" :icon="['fas', 'exclamation-triangle']"/> User account is expiring! <div class="btn btn-success extend" @click="this.$root.gotoRoute({ path: '/extenduser' })">Extend</div></div>


    <div style="display:flex;justify-content: center;" v-if="(this.isAdmin === '1' || this.userInfo.ID === this.userId) && account.EXPIRE_STATUS !== '4'">
      <button class="btn tranBtn inBtn" data-trantype="in" @click="tranBtnClicked('in')" :class="{active:inOut === 'in'}" v-if="this.isAdmin==='1' || this.isBudget ==='1'" ><font-awesome-icon :icon="['fas', 'plus-circle']" /> Money In </button>
      <button class="btn tranBtn outBtn" data-trantype="out" @click="tranBtnClicked('out')" :class="{active:inOut === 'out'}"><font-awesome-icon :icon="['fas', 'minus-circle']" /> Money Out </button>
    </div>

    <hr>

    <div style="display:flex;justify-content: center;" v-if="tranMode">
      <button class="btn tranTypeBtn inBtn" @click="switchActualBudget('actual')" :class="{active:isBudget === '0'}" v-if="this.isAdmin==='1' || this.isBudget ==='1'" ><font-awesome-icon :icon="['fas', 'dollar-sign']" /> Actual </button>
      <button class="btn tranTypeBtn outBtn" @click="switchActualBudget('budget')" :class="{active:isBudget === '1'}"><font-awesome-icon :icon="['fas', 'chart-line']" /> Budget </button>
    </div>

    <div v-show="tranMode">
      <div id="newTranContainer">

        <hr>

        <section>
          <div>Transaction Account</div>
          <select class="form-control validate" id="tranBa" v-model="tranBaId">
            <option v-for="(userTranAccount) in userTranAccounts" :key="userTranAccount.ID" :value="userTranAccount.ID">{{userTranAccount.ACCOUNT_NAME}}</option>
          </select>

        </section>

        <section v-if="isBudget==='1' && !tranId">
          <div>Frequency</div>
          <select id="frequency" class="form-control" v-model="repeatFrequency">
            <option v-for="frequency in repeatFrequencyOptions" :key="frequency.id" :value="frequency.value" >{{frequency.name}}</option>
          </select>
        </section>


        <section id="datePicker" >
          <div v-if="repeatFrequency !== 'oneoff'">Start Date</div>
          <div v-else>Transaction Date</div>
          <div >
            <DatePicker v-model="tranDate" :class="{ 'hasError': tranDateHasError }"  />
          </div>
          <div class="error-feedback" v-if="futureTranDate">Transaction date can't be a future date.</div>
        </section>


        <section class="toggleRow" v-if="isBudget==='1' && !this.convertTranMode && this.isAdmin === '1'">
          <div class="" >Auto Convert to Transaction On the Transaction Date</div>
          <div class="" @click="toggleAutoConvert">
            <font-awesome-icon v-if="autoConvert==='1'" class="toggleFa" :icon="['fas', 'toggle-on']" />
            <font-awesome-icon v-else class="toggleFa" :icon="['fas', 'toggle-off']" />
          </div>
        </section>

        <section v-if="repeatFrequency !== 'oneoff'  && !tranId">
          <div>Repeat times</div>
          <select id="times" class="form-control" v-model="repeatTimes">
            <option v-for="time in repeatTimesOptions" :key="time" :value="time">{{time}}</option>
          </select>
        </section>

        <section class="tab enterAmount show" style="margin:70px 0;">
          <div>Amount</div>
          <div class="input-group mb-3" :class="{ 'hasError': tranAmountHasError }" >
            <span class="input-group-text" @click="amountAdj('minus')"><font-awesome-icon :icon="['fas', 'minus-circle']" /></span>
            <span class="input-group-text">{{this.familyCurrency}}</span>
            <input type="text" class="form-control tranAmount" aria-label="Amount" v-model="tranAmount">
            <span class="input-group-text"  @click="amountAdj('plus')"><font-awesome-icon :icon="['fas', 'plus-circle']" /></span>
          </div>

          <div style="display:flex;justify-content: center;margin-top:20px;">
            <div v-for="increment in increments" :key="increment.id">
              <increment :increment="increment" :incrementActiveId="incrementActiveId" @setIncrementAmount="onSetIncrementAmount"></increment>
            </div>
            <button class="btn clearBtn" @click="set2zero">clr</button>
          </div>
        </section>


        <section id="tranDescContainer">
          <textarea class="form-control" :class="{ 'hasError': tranDescHasError }"  id="Details" rows="3" placeholder="transaction description..." v-model="tranDesc"></textarea>
        </section>



        <section>
          <label class="form-label fromToLabel" for="RelatedAccounts"><span v-if="inOut==='in'">From</span><span v-if="inOut==='out'">To</span> Account</label>
          <select class="form-control validate" :class="{ 'hasError': relatedAcctHasError }" id="RelatedAccounts" v-model="relatedAccountId">
            <option v-for="(familyAccount) in familyRelatedAccounts" :key="familyAccount.ID" :value="familyAccount.ID">{{familyAccount.ACCOUNT_NAME}}</option>
            <option value="0">External Source</option>
          </select>
        </section>

      </div>
    </div>

    <div class="transDiv mt-5"  v-show="!tranMode  && account.EXPIRE_STATUS !== '4'">
      <div class="row toggleRow">
        <div class=" col-auto me-auto" >
          <div style="margin-right:20px;">Top 100 transactions as of</div>
          <div >
            <DatePicker v-model="transAsOfDate" :class="{ 'hasError': tranDateHasError }"  />
          </div>
        </div>
        <div class="col-auto showBudgetDiv clickable" @click="toggleShowBudget">
          <span class="showBudgetTxt">Show Budgets</span>
          <font-awesome-icon v-if="showBudgetTrans" class="toggleFa" :icon="['fas', 'toggle-on']" />
          <font-awesome-icon v-else class="toggleFa" :icon="['fas', 'toggle-off']" />
        </div>
      </div>
      <ul class="transactions mt-2 list2">
        <li class="transaction " :class="{'isBudget': transaction['IS_BUDGET'] === '1'}" v-for="transaction in transactions" :key="transaction.ID" >
          <transaction :transaction="transaction" :isBudget="isBudget" :baId="baId" @editTran="editTranClick" @showDeleteModalFunc="showDeleteModalFunc" @convertTran="convertTran" ></transaction>
        </li>
      </ul>
    </div>

  </div>


  <div class="CompleteBtnDiv" @click="addNewTran" v-if="tranMode">
    <button class="btn btn-warning completeBtn" :disabled="loading">
      <span v-show="loading" class="spinner-border spinner-border-sm" ></span>
      {{saveBtnTxt}}
    </button>
  </div>



  <div class="modalParent">
    <vue-final-modal v-model="showDeleteModal" classes="modal-container" content-class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="">Are you sure you want to delete this budget?</h5>
      </div>

      <div class="modal-body">
        <div style="display:flex;justify-content: center;">
          <div class="btn delConfirmBtn delYes" @click="delTran"><font-awesome-icon :icon="['fas', 'trash']"/> Yes</div>
          <div class="btn delConfirmBtn delNo" @click="showDeleteModal=false"><font-awesome-icon :icon="['fas', 'times']"/> No</div>
        </div>
      </div>
    </vue-final-modal>
  </div>


</template>

<script>
import Transaction from '@/components/Transaction';
import Increment from '@/components/Increment';
import DatePicker from '@/components/DatePicker';
import {VueFinalModal } from 'vue-final-modal';
import currencies from "@/services/currencies.json";

var d = new Date();
console.log(d.toLocaleDateString());
d.setMonth(d.getMonth() + 3);

export default {
  name: 'Transactions',
  /*props: {
    isBudget:String
  },*/
  components: {
    Transaction, Increment, DatePicker,
    VueFinalModal

  },
  data(){
    return{
      account: {},
      userInfo:{},
      isAdmin:'0',
      isBudget:null,

      baId:null,
      userId:null,
      tranId:null,
      //pageTitle:'',
      transactions:null,
      tranMode:false,
      inOut:'',
      tranBaId:null,
      tranBaName:null,
      convertTranMode:false,
      showBudgetTrans:true,
      transAsOfDate:d,

      familyCurrency:'$',


      familyAccounts:{},
      familyRelatedAccounts:{},
      userTranAccounts:{},
      numIncreMode:'plus',
      tranAmount: '0',
      tranAmountHasError: false,
      incrementAmount:5,
      //saveBtnTxt:'Add New Transaction',
      increments: [
        {id:1, name:'$5',amount:5},
        {id:2, name:'$1',amount:1},
        {id:3, name:'¢25',amount:0.25},
        {id:4, name:'¢10',amount:0.10},
        {id:5, name:'¢5',amount:0.05},
        {id:6, name:'¢1',amount:0.01}
      ],
      incrementActiveId:1,
      tranDesc:'',
      tranDescHasError:false,
      relatedAccountId: '',
      autoConvert:'0',
      tranDate:  new Date(),
      tranDateYmd:'',
      tranDateHasError:false,
      futureTranDate:false,
      relatedAcctHasError:false,
      endDate:  null,
      repeatTimesOptions:12,
      repeatTimes:1,
      repeatFrequency:'oneoff',
      repeatFrequencyOptions:[
        {id:1, name:'One Off',value:'oneoff'},
        {id:2, name:'Daily',value:'daily'},
        {id:3, name:'Weekly',value:'weekly'},
        {id:4, name:'Monthly',value:'monthly'},
        {id:5, name:'Quarterly',value:'quarterly'},
        {id:6, name:'Semi-annually',value:'semiannually'},
        {id:7, name:'Annually',value:'annually'},

      ],

      showDeleteModal:false,
      showPickBasModal:false,
      loading:false

    }
  },

  computed:{
    saveBtnTxt() {
      return this.isBudget === '1' ? 'Add New Budget' : 'Add New Transaction';
    },


  },

  methods:{
    getTransactions: function(){
      let loader = this.$loading.show();
      this.axios
          .post(this.$hostApi,{task:'getTransactions',baId:this.baId, isBudget:this.isBudget, transAsOfDate:this.transAsOfDate.toLocaleDateString('en-CA'), showBudgetTrans:this.showBudgetTrans},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            this.transactions = response.data;
            loader.hide();
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },


    getPageTitle:function(){
      this.pageTitle = this.isBudget && this.isBudget === '1' ? 'Budgeting - ' + this.account.FULL_NAME : 'New Transaction - ' + this.account.ACCOUNT_NAME;
    },

    tranBtnClicked:function(btn){
      this.$root.playClickSound();

      this.inOut = btn;

      this.tranDateHasError = false;
      this.tranDescHasError = false;
      this.tranAmountHasError = false;
      this.futureTranDate = false;

      if(this.isBudget === null) this.isBudget = '0';

      if(this.tranMode === false){
        this.formReset();
        this.tranMode = true;
      }


    },

    switchActualBudget(tranType){
      this.$root.playClickSound();
      this.isBudget = tranType === 'actual' ? '0' : '1';
    },

    toggleShowBudget(){
      this.showBudgetTrans = !this.showBudgetTrans;
      this.getTransactions();
    },

    toggleAutoConvert(){
      this.autoConvert = this.autoConvert === '1' ? '0' : '1';
    },


    amountAdj:function(dir){
      this.$root.playClickSound();
      if(dir==='plus'){
        this.tranAmount = parseFloat(this.numDeformat(this.tranAmount)) + parseFloat(this.incrementAmount);
      }
      if(dir==='minus'){
        this.tranAmount = parseFloat(this.numDeformat(this.tranAmount)) - parseFloat(this.incrementAmount);
        if(this.tranAmount < 0)  this.tranAmount = 0;
      }

    },
    set2zero:function(){
      this.tranAmount = 0;
    },
    onSetIncrementAmount(increment) {
      this.incrementActiveId = increment.id;
      this.incrementAmount = increment.amount;
    },
    numDeformat:function(n){
      return parseFloat(n.replace(/,/g, ''));
    },
    getLocalDateString:function(d){
      return d.toLocaleDateString('en-CA');
    },



    getFamilyAccounts: function(){
      this.axios
          .post(this.$hostApi,{task:'getFamilyAccounts',baId:this.baId},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            this.familyAccounts = response.data;
            //console.log(response);
            this.account = this.getBaById(this.baId);
            this.userId = this.account.USER_ID;
            //console.log(this.account);
            this.getPageTitle();

            const centralBank = this.familyAccounts.filter(ba => ba.CENTRAL_BANK === "1");
            //console.log(centralBank);
            if(centralBank) this.relatedAccountId = centralBank[0].ID;

            this.familyRelatedAccounts = this.familyAccounts.filter(ba => ba.ACCOUNT_TYPE !== "budgeting" && ba.ID !== this.baId);
            this.userTranAccounts = this.familyAccounts.filter(ba => ba.ACCOUNT_TYPE !== "budgeting" && ba.USER_ID === this.account.USER_ID);
            //console.log(this.userTranAccounts);
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },

    getBaById:function(baId){
      return this.familyAccounts.filter(ba => ba.ID === baId)[0];
    },

    editTran: function(tranId){
      this.$root.playClickSound();

      this.tranId = tranId;
      this.axios
          .post(this.$hostApi,{task:'getTran',tranId:tranId},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            const tran = response.data;
            //console.log(tran);
            this.tranAmount = Math.abs(parseFloat(tran.AMOUNT));
            const td = new Date(tran.TRAN_DATE);
            td.setDate(td.getDate() + 1);
            this.tranDate = td;
            this.tranDesc = tran.DETAILS;
            this.autoConvert = tran.AUTO_CONVERT;
            if(parseFloat(tran.AMOUNT) >= 0){
              this.inOut = 'in';
            }else{
              this.inOut = 'out';
            }
            this.tranMode = true;

            if(this.convertTranMode){
              this.pageTitle = "Covert Budget #" + this.tranId + " to Transaction";
              this.saveBtnTxt ="Covert Budget #" + this.tranId + " to Transaction";
            }else{
              this.pageTitle = "Edit Budget #" + this.tranId;
              this.saveBtnTxt = 'Update Budget #' + this.tranId;
            }
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },

    addNewTran: function(){
      this.$root.playClickSound();
      this.loading = true;

      const tranAmount2 = this.numDeformat(this.tranAmount);

      const amount = this.inOut === 'in' ? tranAmount2 : tranAmount2 * -1;
      const tranDesc = this.tranDesc;
      const relatedAccountId = this.relatedAccountId;

      this.tranAmountHasError = false;
      this.tranDescHasError = false;
      this.tranDateHasError = false;
      this.futureTranDate = false;
      this.relatedAcctHasError = false;

      if(tranAmount2 <= 0 || tranAmount2 > 100000) {this.tranAmountHasError = true; }
      if(tranDesc.length === 0){this.tranDescHasError = true; }
      if(this.tranDate.length === 0){this.tranDateHasError = true; }
      if(this.isBudget === '0' || this.convertTranMode){
        this.tranDate.setHours(0,0,0,0);
        if(this.tranDate > new Date()) {
          console.log(this.tranDate);
          console.log(new Date());

          this.tranDateHasError = true;
          this.futureTranDate = true;
        }
      }
      if(this.relatedAccountId === this.baId){this.relatedAcctHasError = true; }
      console.log(this.relatedAccountId);


      if(this.tranAmountHasError || this.tranDescHasError || this.tranDateHasError || this.futureTranDate || this.relatedAcctHasError){
        this.loading = false;
        return;
      }

      this.tranDateYmd = this.getLocalDateString(this.tranDate);



      if(this.tranId){
        if(this.convertTranMode){
          this.axios
              .post(this.$hostApi,{task:'convertTran',baId:this.baId, tranId:this.tranId, tranBaId:this.tranBaId, amount:amount, tranDesc:tranDesc, tranDate:this.tranDateYmd, relatedAccountId:relatedAccountId},{
                headers:{
                  'token':localStorage.getItem('user')
                }
              })
              .then(response =>{
                this.getTransactions();
                this.tranMode = false;
                this.convertTranMode = false;
                this.getPageTitle();
                this.loading = false;
              })
              .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
              })
        }else{
          this.axios
              .post(this.$hostApi,{task:'updateTran',baId:this.baId, tranId:this.tranId, amount:amount, tranDesc:tranDesc, tranDate:this.tranDateYmd, autoConvert:this.autoConvert},{
                headers:{
                  'token':localStorage.getItem('user')
                }
              })
              .then(response =>{
                this.getTransactions();
                this.tranMode = false;
                this.getPageTitle();
                this.loading = false;
              })
              .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
              })
        }

      }else{
        this.axios
            .post(this.$hostApi,{task:'addNewTran',baId:this.baId, isBudget:this.isBudget, amount:amount, tranDesc:tranDesc, repeatFrequency:this.repeatFrequency, tranDate:this.tranDateYmd, autoConvert:this.autoConvert, repeatTimes:this.repeatTimes, relatedAccountId:relatedAccountId},{
              headers:{
                'token':localStorage.getItem('user')
              }
            })
            .then(response =>{
              this.getTransactions();
              this.tranMode = false;
              this.loading = false;
            })
            .catch(error => {
              this.errorMessage = error.message;
              console.error("There was an error!", error);
            })
      }

    },

    delTran:function(){
      this.$root.playClickSound();
      this.axios
          .post(this.$hostApi,{task:'delTran', baId:this.baId, tranId:this.tranId},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            this.tranId = null;
            this.showDeleteModal = false;
            this.getTransactions();
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("error deleting user!", error);
          })
    },





    formReset: function(){
      this.tranId = null;
      this.repeatTimes = 1;
      this.repeatFrequency = 'oneoff';
      this.tranAmount = '0';
      //this.incrementAmount = 5;
      this.tranDesc = '';

      this.tranDate = new Date();
    },

    showHistory: function(){
      this.$root.playClickSound();
      this.tranMode = false;
      this.formReset();
      this.isBudget = null;
      this.inOut = '';
      this.getPageTitle();
    },

    setFormState: function(state){
      switch(state) {
        case 'tran-empty':
          this.tranId = null;
          this.repeatTimes = 1;
          this.repeatFrequency = 'oneoff';
          this.tranAmount = '0';
          this.tranDesc = '';
          this.saveBtnTxt = 'Add New Transaction';
          break;

        case 'budget-empty':
          this.tranId = null;
          this.repeatTimes = 1;
          this.repeatFrequency = 'oneoff';
          this.tranAmount = '0';
          this.tranDesc = '';
          this.saveBtnTxt = 'Add New Transaction';
          break;

        case 'convertTran':
          this.repeatTimes = 1;
          this.repeatFrequency = 'oneoff';
          this.tranAmount = '0';
          this.tranDesc = '';
          this.saveBtnTxt = 'Add New Transaction';
          break;

        default:
          // code block
      }
    },

    showDeleteModalFunc:function(tranId){
      this.$root.playClickSound();
      this.showDeleteModal = true;
      this.tranId = tranId;
    },

    convertTran:function(tranId){
      this.showPickBasModal = false;
      this.tranId = tranId;
      this.tranBaId = this.userTranAccounts[0].ID;
      this.convertTranMode = true;
      this.editTran(this.tranId);
    },

    editTranClick:function(tranId){
      this.tranId = tranId;
      this.convertTranMode = false;
      this.editTran(this.tranId);
    }

  },

  created() {
    this.baId = this.$route.query.baId;
    this.tranBaId = this.baId;

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
    this.isAdmin = userInfo && userInfo.FAMILY_ADMIN ? userInfo.FAMILY_ADMIN : '0';

    const familyInfo = JSON.parse(localStorage.getItem('familyInfo'));
    this.familyCurrency = currencies.filter(c => c.cc === familyInfo.CURRENCY_CODE)[0].symbol;

    this.increments = [
      {id:1, name:this.familyCurrency+'5',amount:5},
      {id:2, name:this.familyCurrency+'1',amount:1},
      {id:3, name:'¢25',amount:0.25},
      {id:4, name:'¢10',amount:0.10},
      {id:5, name:'¢5',amount:0.05},
      {id:6, name:'¢1',amount:0.01}
    ];
  },

  watch: {

    tranAmount(newVal, oldVal) {
      this.tranAmount = this.vueNumberFormat(this.tranAmount);
    },

    transAsOfDate(newVal, oldVal) {
      this.getTransactions();
    },
  },

  mounted() {

    if(this.isBudget === '1') this.saveBtnTxt = 'Add New Budget';
    this.getFamilyAccounts();
    this.getTransactions();



  }
}
</script>

<style scoped>

#mainContainer{
  margin:70px auto 100px auto;
  padding:20px 10px 10px 10px;
}

section{
  margin:10px 0;
}

.tranBtn, .tranTypeBtn{
  margin:5px;
  width:46%;
  background-color:#f5f5f5;
}





.inBtn.active{
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.outBtn.active{
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}



/*>>>>>>>List 2>>>>>>>>>>>>>>>>>>*/

.list2 .avatar img{
  width:40px;
  border:2px solid #9c9c9c;
  border-radius: 50%;
  padding:3px;
}

.list2 li.active{
  border:2px solid #0e90d2;
}

.list2 li{
  position:relative;
  background-color: #f5f5f5;
  border: 2px solid #fff;
  margin: 1px;
}

.list2 li.isBudget{
  background-color: #f2f5a8;
}

.email{
  font-size:12px;
}




.selectDiv{
  position:absolute;
  right:10px;
  width:50px;
}

.selectSpot{
  color:#fff;
}

li.active .selectSpot{
  color: #0e90d2;
}






/*<<<<<<<<<<<<<<List 2<<<<<<<<<<<<<<*/



input.tranAmount{
  height:120px;
  font-size:120px;
  font-weight:bold;
  text-align:center;
  font-family: Arial, sans-serif;
  line-height: 120px;
}



#tranDescContainer{
  margin-bottom:50px;
}

#Details{
  background-color: #f5f5f5;
}


.completeBtn{
  width:100%;
  border-radius: unset;
  height:70px;
}

.fromToLabel{
  text-align: left;
  float:left;
  font-weight:bold;
}



@media only screen and (max-width: 600px) {


  input.tranAmount{
    height:60px;
    font-size:50px;
    font-weight:bold;
    line-height: 60px;
  }
}







.CompleteBtnDiv{
  width: calc(100vw + 20px);
  left:-10px;
  height:70px;
  position:fixed;bottom:0;display:flex;align-items:center;justify-content: center;z-index:1001;
}

.clearBtn{
  font-size:10px;
  margin:5px;
}

.hasError{
  border:1px solid red;
}




.flex {
  display: flex;
  justify-content: left;
  align-items: center;
}

#actionBtnsContainer {
  position: fixed;
  bottom: 100px;
  right: 50px;
  height: 45px;
  color: #fff;
}

#actionBtnsDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionDiv {
  margin-left: 10px;

}

.actionBtn {
  width: 45px;
  height: 45px;
  background-color: #0dcaf0;
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
  text-align: center;
  display:flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.actionTxt {
  color: #3b3b3b;
  font-size: 8px;
  height: 10px;
  text-align: center;
}

.modal-dialog.fullwidth {
  width: 100% !important;
  max-width: 100% !important;
}

.delYes {
  background-color: red;
  color: #fff;
}

.delConfirmBtn {
  text-align: center;
  border: 1px solid #f5f5f5;
  margin: 10px;
}

.delNo {
  background-color: #e4e4e4;
  color: #3b3b3b;
}

.delConfirmBtn svg {
  margin-right: 10px;
}










.tranAccounts li{
  margin:5px 0;
  background-color: #e8e5e5;
  padding:3px 10px;
  border-radius: 2px;
  font-size:14px;
}

.error-feedback{
  font-size:10px;
  color:red;

}

.extend{
  margin-left:20px;
  font-size:12px;
  padding:5px 10px;
}

.expireIcon{
  margin-right:10px;
}

.expireBanner{
  display:flex;
  align-items: center;
}


.tranTypeBtn.active{
  color: #fff;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.showBudgetTxt{
  font-size:12px;
  margin-right:10px;
}

.showBudgetDiv{
  margin:15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}



/*modal*/
.modalParent :deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalParent :deep(.modal-content){
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem !important;
  padding: 1rem !important;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width:unset;

}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 14px;
  font-weight: 700;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: 0 solid #e2e8f0;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.toggleRow{
  display:flex;
  justify-content: space-between;
  margin-top:30px;
}

.toggleFa{
  font-size:30px;
  margin-right:10px;
}


</style>