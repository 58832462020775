<template>
<div class="tran clickable" v-on:click="showHideDetails">
  <div class="basic">
    <div class="flexdiv">
      <div class="showExtra">
        <font-awesome-icon :icon="['fas', 'caret-right']" v-if="extraIsHidden"/>
        <font-awesome-icon :icon="['fas', 'caret-down']" v-if="!extraIsHidden"/>
      </div>
      <div class="tranDate">{{transaction.TRAN_DATE}}</div>
      <div class="tranDesc">{{transaction.DETAILS}}</div>
    </div>
    <div class="amount" :class="numberColor(transaction.AMOUNT)">{{transaction.AMOUNT}}</div>
  </div>
  <div v-if="!extraIsHidden">
    <div class="extra" >
      <div>
        #{{ transaction.ID }} by {{this.getEmailName(transaction.CREATED_BY)}}
      </div>
      <div>
        {{ transaction.ACCOUNT_NAME_RELATED }}
      </div>
    </div>

    <div class="tranActionIconsDiv" v-if="(isAdmin || userInfo.EMAIL === transaction.CREATED_BY)">

      <div class="btn btn-danger delTranBtn tranBtn" @click="showDeleteModal(transaction.ID)"><font-awesome-icon :icon="['fas', 'trash']"/></div>
      <div class="btn btn-warning editTranBtn tranBtn" @click="$emit('editTran',{tranId:transaction.ID,isSchedule:this.isSchedule})"><font-awesome-icon :icon="['fas', 'pencil-alt']"/></div>
    </div>
    <div class="tranActionIconsDiv" v-if="isAdmin && 1===2">
      <div class="btn btn-primary delTranBtn tranBtn" @click="gotoShareTran(transaction.ID)"><font-awesome-icon :icon="['fas', 'share']"/></div>
    </div>
  </div>

</div>

</template>

<script>

export default {
  name: 'Transaction',
  props: {
    transaction: {},
    isBudget:String,
    baId:String,
    isAdmin:Boolean,
    userInfo:{},
  },
  data(){
    return{
      extraIsHidden:true
    }
  },
  computed:{
    isSchedule(){
      return this.transaction.SCHEDULE_ID ? '1' : '0';
    }
  },
  methods:{
    numberColor:function(num){
      return num < 0 ? 'negative' : '';
    },

    gotoShareTran:function(tranId){
      this.$root.gotoRoute({ path: '/share', query: { tranId: tranId } });
    },
    showDeleteModal:function(tranId){
      this.$emit('showDeleteModalFunc',tranId);
    },
    showHideDetails:function(){
      this.$root.playClickSound();
      this.extraIsHidden = !this.extraIsHidden
    },
    getEmailName:function(email){
      return email ? email.substring(0, email.lastIndexOf("@")) : '';
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tran{
  font-size:12px;

  min-height:30px;
  padding:3px 5px;
  margin:1px;
}

.basic{
  display:flex;
  justify-content: space-between;
}
.amount{
  float:right;
}

.tranDate{
  margin-right:10px;
  width:70px;
}

.tranDesc{
  flex: 1;
  padding-right:10px;
}

.flexdiv{
  display:flex;
  justify-content: left;
}

.showExtra{
  width:25px;
  text-align: center;
}

.fa-caret{
  color: #787878;
}

.positive{
  color:green;
}

.negative{
  color:red;
}



.extra{
  display:flex;
  justify-content: space-between;
  background-color: #ffffff;
  font-size:10px;
  padding:5px 10px;
}

.tranBtn{
  font-size:15px;
  padding:2px 10px;
  margin:0 10px;
}

.tranActionIconsDiv{
  padding:10px;
  display:flex;
  justify-content: center;
}



</style>
